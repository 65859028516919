@charset "UTF-8";
@import "mixin";
.m-inner {
  width: 1000px;
  margin: 0 auto;
  @include sp {
    width: 100%;
    padding: 0 15px;
  }
}
.m-button { //miyake
  padding: 16px 0;
  font-weight: 900;
  text-align: center;
  display: block;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  position: relative;
  transition: .3s;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16px 16px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    top: 8px;
    right: 8px;
    transition: 0.3s;
  }
  &:hover {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  &:hover:after {
    border-width: 0 20px 20px 0;
  }
  &-blue {
    color: #fff;
    background: $colorBlue;
  }
  &-black {
    color: #fff;
    background: $colorBlack;
  }
  &-lightblue {
    color: #fff;
    background: $colorLightBlue;
  }
  &-white {
    color: $colorBlue;
    background: #fff;
    &:after {
      border-color: transparent $colorBlue transparent transparent;
    }
  }
  &-green {
    color: #fff;
    background: $colorGreen;
    border: 1px solid;
  }
  &-navy {
    color: #fff;
    background: $colorNavy;
    border: 1px solid;
  }
  &-lightgreen {
    color: #fff;
    background: $colorLightGreen;
    border: 1px solid;
  }
  &-gray {
    color: #fff;
    background: #999;
  }
}
// izumi
// わたしのキャリアチェンジ
.m-my_career_change {
  background-color: $colorBlue;
  padding: 56px 0;
  @include sp {
    padding-top: 32px;
  }
  &--inner {
    position: relative;
  }
  &--title {
    color: #fff;
    font-size: 40px;
    font-weight: $fwBlack;
    margin: 0 0 24px 0;
    @include sp {
      font-size: 24px;
      line-height: 1.5;
      text-align: center;
    }
  }
  &--description {
    color: #fff;
    font-size: 20px;
    font-weight: $fwBold;
    line-height: 2;
    margin: 0 0 40px 0;
    @include sp {
      font-size: 16px;
      margin-bottom: 32px;
    }
  }
  &--img {
    position: absolute;
    right: 25px;
    top: -21px;
    @include sp {
      position: static;
      display: block;
      width: 280px;
      margin: 0 auto 32px;
    }
  }
  &--link {
    width: 280px;
    @include sp {
      margin: 0 auto;
    }
  }
}
// izumi
// まずは一度ご相談ください
.m-please_contact {
  background-color: #F5F5F5;
  padding: 56px 0 80px 0;
  @include sp {
    padding-top: 40px;
    padding-bottom: 56px;
  }
  &.is_show {
    .m-please_contact {
      &--title {
        animation: fadeInAndScaleUp 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
      }
      &--callout {
        @for $i from 1 through 4 {
          &:nth-of-type(#{$i}) {
            animation: fadeInAndScaleUp 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards #{$i * 0.1}s;
          }
        }
      }
    }
  }
  &--inner {
    position: relative;
  }
  &--title {
    font-size: 40px;
    font-weight: $fwBlack;
    text-align: center;
    margin: 0 0 194px 0;
    opacity: 0;
    transform: scale(0.95);
    @include sp {
      font-size: 24px;
      margin-bottom: 270px;
    }
  }
  &--callout {
    position: absolute;
    background-color: #fff;
    font-size: 20px;
    font-weight: $fwBold;
    padding: 14px 24px;
    border-radius: 30px;
    filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.32));
    line-height: 1.5;
    opacity: 0;
    transform: scale(0.95);
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }
    &:nth-of-type(1) {
      left: 259px;
      top: 97px;
      &:before {
        border-color: transparent #fff transparent transparent;
        border-width: 0 16px 32px 0;
        left: 166px;
        bottom: -32px;
        @include sp {
          left: 41px;
        }
      }
      @include sp {
        left: 24px;
        top: 50px;
      }
    }
    &:nth-of-type(2) {
      left: 506px;
      top: 121px;
      &:before {
        border-color: #fff transparent transparent transparent;
        border-width: 32px 16px 0 0;
        bottom: -32px;
        left: 40px;
        @include sp {
          left: 160px;
        }
      }
      @include sp {
        left: auto;
        right: 24px;
        top: 115px;
      }
    }
    &:nth-of-type(3) {
      left: 33px;
      top: 198px;
      &:before {
        border-color: transparent #fff transparent transparent;
        border-width: 0 16px 24px 0;
        left: 230px;
        bottom: -24px;
        @include sp {
          left: 34px;
        }
      }
      @include sp {
        top: 160px;
        left: 24px;
      }
    }
    &:nth-of-type(4) {
      left: 720px;
      top: 188px;
      &:before {
        border-color: #fff transparent transparent transparent;
        border-width: 24px 16px 0 0;
        bottom: -24px;
        left: 40px;
        @include sp {
          left: 150px;
        }
      }
      @include sp {
        left: auto;
        right: 24px;
        top: 224px;
      }
    }
    @include sp {
      font-size: 16px;
      padding: 8px 16px;
      border-radius: 20px;
      line-height: 1.3;
    }
  }
  &--description {
    font-size: 24px;
    font-weight: $fwBold;
    text-align: center;
    margin: 0 0 40px 0;
    line-height: 1.5;
    @include sp {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  &--link_wrap {
    text-align: center;
  }
  &--link {
    display: inline-block;
    width: 280px;
    margin: 0 49px 0 0;
    &:last-child {
      margin-right: 0;
      @include sp {
        margin-right: auto;
      }
    }
    @include sp {
      display: block;
      margin-right: auto;
      margin-left: auto;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
}
//miyake
.m-sp {
  display: none;
  @include sp {
    display: block;
  }
}
.m-pc {
  display: block;
  @include sp {
    display: none;
  }
}
@keyframes fadeInAndScaleUp {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
