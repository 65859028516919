.m-inner {
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 425px) {
  .m-inner {
    width: 100%;
    padding: 0 15px;
  }
}

.m-button {
  padding: 16px 0;
  font-weight: 900;
  text-align: center;
  display: block;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  position: relative;
  transition: .3s;
}

.m-button:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  top: 8px;
  right: 8px;
  transition: 0.3s;
}

.m-button:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.m-button:hover:after {
  border-width: 0 20px 20px 0;
}

.m-button-blue {
  color: #fff;
  background: #17ABED;
}

.m-button-black {
  color: #fff;
  background: #333333;
}

.m-button-lightblue {
  color: #fff;
  background: #64BFCB;
}

.m-button-white {
  color: #17ABED;
  background: #fff;
}

.m-button-white:after {
  border-color: transparent #17ABED transparent transparent;
}

.m-button-green {
  color: #fff;
  background: #008802;
  border: 1px solid;
}

.m-button-navy {
  color: #fff;
  background: #00467E;
  border: 1px solid;
}

.m-button-lightgreen {
  color: #fff;
  background: #3BCCA5;
  border: 1px solid;
}

.m-button-gray {
  color: #fff;
  background: #999;
}

.m-my_career_change {
  background-color: #17ABED;
  padding: 56px 0;
}

@media (max-width: 425px) {
  .m-my_career_change {
    padding-top: 32px;
  }
}

.m-my_career_change--inner {
  position: relative;
}

.m-my_career_change--title {
  color: #fff;
  font-size: 40px;
  font-weight: 900;
  margin: 0 0 24px 0;
}

@media (max-width: 425px) {
  .m-my_career_change--title {
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
  }
}

.m-my_career_change--description {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 2;
  margin: 0 0 40px 0;
}

@media (max-width: 425px) {
  .m-my_career_change--description {
    font-size: 16px;
    margin-bottom: 32px;
  }
}

.m-my_career_change--img {
  position: absolute;
  right: 25px;
  top: -21px;
}

@media (max-width: 425px) {
  .m-my_career_change--img {
    position: static;
    display: block;
    width: 280px;
    margin: 0 auto 32px;
  }
}

.m-my_career_change--link {
  width: 280px;
}

@media (max-width: 425px) {
  .m-my_career_change--link {
    margin: 0 auto;
  }
}

.m-please_contact {
  background-color: #F5F5F5;
  padding: 56px 0 80px 0;
}

@media (max-width: 425px) {
  .m-please_contact {
    padding-top: 40px;
    padding-bottom: 56px;
  }
}

.m-please_contact.is_show .m-please_contact--title {
  animation: fadeInAndScaleUp 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
}

.m-please_contact.is_show .m-please_contact--callout:nth-of-type(1) {
  animation: fadeInAndScaleUp 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards 0.1s;
}

.m-please_contact.is_show .m-please_contact--callout:nth-of-type(2) {
  animation: fadeInAndScaleUp 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards 0.2s;
}

.m-please_contact.is_show .m-please_contact--callout:nth-of-type(3) {
  animation: fadeInAndScaleUp 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards 0.3s;
}

.m-please_contact.is_show .m-please_contact--callout:nth-of-type(4) {
  animation: fadeInAndScaleUp 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards 0.4s;
}

.m-please_contact--inner {
  position: relative;
}

.m-please_contact--title {
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  margin: 0 0 194px 0;
  opacity: 0;
  transform: scale(0.95);
}

@media (max-width: 425px) {
  .m-please_contact--title {
    font-size: 24px;
    margin-bottom: 270px;
  }
}

.m-please_contact--callout {
  position: absolute;
  background-color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 14px 24px;
  border-radius: 30px;
  filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.32));
  line-height: 1.5;
  opacity: 0;
  transform: scale(0.95);
}

.m-please_contact--callout:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}

.m-please_contact--callout:nth-of-type(1) {
  left: 259px;
  top: 97px;
}

.m-please_contact--callout:nth-of-type(1):before {
  border-color: transparent #fff transparent transparent;
  border-width: 0 16px 32px 0;
  left: 166px;
  bottom: -32px;
}

@media (max-width: 425px) {
  .m-please_contact--callout:nth-of-type(1):before {
    left: 41px;
  }
}

@media (max-width: 425px) {
  .m-please_contact--callout:nth-of-type(1) {
    left: 24px;
    top: 50px;
  }
}

.m-please_contact--callout:nth-of-type(2) {
  left: 506px;
  top: 121px;
}

.m-please_contact--callout:nth-of-type(2):before {
  border-color: #fff transparent transparent transparent;
  border-width: 32px 16px 0 0;
  bottom: -32px;
  left: 40px;
}

@media (max-width: 425px) {
  .m-please_contact--callout:nth-of-type(2):before {
    left: 160px;
  }
}

@media (max-width: 425px) {
  .m-please_contact--callout:nth-of-type(2) {
    left: auto;
    right: 24px;
    top: 115px;
  }
}

.m-please_contact--callout:nth-of-type(3) {
  left: 33px;
  top: 198px;
}

.m-please_contact--callout:nth-of-type(3):before {
  border-color: transparent #fff transparent transparent;
  border-width: 0 16px 24px 0;
  left: 230px;
  bottom: -24px;
}

@media (max-width: 425px) {
  .m-please_contact--callout:nth-of-type(3):before {
    left: 34px;
  }
}

@media (max-width: 425px) {
  .m-please_contact--callout:nth-of-type(3) {
    top: 160px;
    left: 24px;
  }
}

.m-please_contact--callout:nth-of-type(4) {
  left: 720px;
  top: 188px;
}

.m-please_contact--callout:nth-of-type(4):before {
  border-color: #fff transparent transparent transparent;
  border-width: 24px 16px 0 0;
  bottom: -24px;
  left: 40px;
}

@media (max-width: 425px) {
  .m-please_contact--callout:nth-of-type(4):before {
    left: 150px;
  }
}

@media (max-width: 425px) {
  .m-please_contact--callout:nth-of-type(4) {
    left: auto;
    right: 24px;
    top: 224px;
  }
}

@media (max-width: 425px) {
  .m-please_contact--callout {
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 20px;
    line-height: 1.3;
  }
}

.m-please_contact--description {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 40px 0;
  line-height: 1.5;
}

@media (max-width: 425px) {
  .m-please_contact--description {
    font-size: 16px;
    margin-bottom: 24px;
  }
}

.m-please_contact--link_wrap {
  text-align: center;
}

.m-please_contact--link {
  display: inline-block;
  width: 280px;
  margin: 0 49px 0 0;
}

.m-please_contact--link:last-child {
  margin-right: 0;
}

@media (max-width: 425px) {
  .m-please_contact--link:last-child {
    margin-right: auto;
  }
}

@media (max-width: 425px) {
  .m-please_contact--link {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .m-please_contact--link:first-child {
    margin-bottom: 16px;
  }
}

.m-sp {
  display: none;
}

@media (max-width: 425px) {
  .m-sp {
    display: block;
  }
}

.m-pc {
  display: block;
}

@media (max-width: 425px) {
  .m-pc {
    display: none;
  }
}

@keyframes fadeInAndScaleUp {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
